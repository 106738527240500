



































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    ConnectMetamask: () => import('../../../components/ConnectMetamask.vue')
  }
})
export default class Claimer extends Vue {
  wallet = walletStore

  networks = [
    {
      chain: 'BSC',
      chainId: 97,
      name: 'Binance Smart Chain Testnet'
    },
    {
      chain: 'BSC',
      chainId: 56,
      name: 'Binance Smart Chain Mainnet'
    }
  ]
  selectedNetwork = this.networks.find((item: any) => item.chainId === this.wallet.requestedChainId) || this.networks[0]
}
